<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form @submit="saveCanteenBills" method="post" action="javascript:void(0)" ref="form_student_bill"
        class="repeater-form">
        <b-row>

          <b-col md="3">
            <b-form-group>
              <b-card-text>
                <span>{{  $t("Fee Head")  }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('Class')" rules="required">
                <select v-model.number="payment_head_id" class="form-control" name="payment_head_id" id="payment_head_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select Head</option>
                  <option v-for="(head, key) in student_fee_heads" :key="key" :value="head.id">
                    {{  head.name  }}
                  </option>
                </select>
                <small class="text-danger">{{  errors[0]  }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <b-card-text>
                <span>{{  $t("Flat")  }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('Class')" rules="required">
                <select v-model.number="version_id" class="form-control" name="version_id" id="version_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select Flat</option>
                  <option v-for="(ver, key) in versions" :key="key" :value="ver.id">
                    {{  ver.name  }}
                  </option>
                </select>
                <small class="text-danger">{{  errors[0]  }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- <b-col md="3">
            <b-form-group>
              <b-card-text>
                <span>{{  $t("Class")  }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('Class')" rules="required">
                <select v-model.number="classes_id" class="form-control" name="classes_id" id="classes_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Class</option>
                  <option v-for="(cls, key) in classes" :key="key" :value="cls.id">
                    {{  cls.name  }}
                  </option>
                </select>
                <small class="text-danger">{{  errors[0]  }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <!-- <b-col md="3">
            <b-form-group>
              <b-card-text>
                <span>{{  $t("Wing")  }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('wings')" rules="required">
                <select v-model.number="wing_id" class="form-control" name="wing_id" id="wing_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Institution</option>
                  <option v-for="(wing, key) in wings" :key="key" :value="wing.id">
                    {{  wing.name  }}
                  </option>
                </select>
                <small class="text-danger">{{  errors[0]  }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <!-- <b-col md="3">
            <b-form-group>
              <b-card-text>
                <span>{{  $t("Group")  }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('wings')" rules="required">
                <select v-model.number="student_group_id" class="form-control" name="student_group_id"
                  id="student_group_id" :state="errors.length > 0 ? false : null">
                  <option :value="null">Group</option>
                  <option v-for="(group, key) in student_groups" :key="key" :value="group.id">
                    {{  group.name  }}
                  </option>
                </select>
                <small class="text-danger">{{  errors[0]  }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col md="3">
            <b-form-group>
              <b-card-text>
                <span>{{  $t("Academic Year")  }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('Academic Year')" rules="">
                <select v-model.number="academic_year_id" class="form-control" name="academic_year_id"
                  id="academic_year_id" :state="errors.length > 0 ? false : null">
                  <option :value="null">Academic Year</option>
                  <option v-for="(year, key) in academic_years" :key="key" :value="year.id">
                    {{  year.year  }}
                  </option>
                </select>
                <small class="text-danger">{{  errors[0]  }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <b-card-text>
                <span>{{  $t("Payment Month ")  }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="student payment month year" rules="required">

                <select name="month_year" v-model="month_year" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  @change="getUploadedBill();" class="form-control" id="month_year">
                  <option :value="null">Month</option>
                  <option :value="1">January</option>
                  <option :value="2">February</option>
                  <option :value="3">March</option>
                  <option :value="4">April</option>
                  <option :value="5">May</option>
                  <option :value="6">June</option>
                  <option :value="7">July</option>
                  <option :value="8">August</option>
                  <option :value="9">September</option>
                  <option :value="10">October</option>
                  <option :value="11">November</option>
                  <option :value="12">December</option>
                </select>
                <small class="text-danger">{{  errors[0]  }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <b-card-text>
                <span>Payment Last Date </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="payment last date" rules="required">
                <b-form-datepicker v-model="payment_last_date" name="payment_last_date" placeholder="payment due date"
                  :state="errors.length > 0 ? false : null"></b-form-datepicker>
                <small class="text-danger">{{  errors[0]  }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>

        <table id="student_canteen_bills">
          <tr>
            <td>{{  $t("Student No")  }} </td>
            <td width="200">{{  $t("Student Name")  }} </td>
            <td v-for="(item, index) in getFeeTypeList" :key="index">{{ item.name  }}</td>
          </tr>
          <tr v-for="(item, index) in studentList" :id="item.id" :key="index" ref="row">
            <td>
              <b-form-input :value="cadetIdByID(item.id)" readonly :id="item.id.toString()"
                :placeholder="$t('Student No')" />
            </td>
            <td>
              <b-form-input :value="item.nick_name+' - '+ studentNameByID(item.id)" readonly :placeholder="$t('student name')" />
            </td>
            <td v-for="(item2, index2) in getFeeTypeList" :key="index2">
              <b-form-input :value="getBillInfo(item.id, item2.id)" :placeholder="$t('Payable amount')"
                @change = "saveChangedCanteenBill($event, item.id, item2.id)"
                :id="item2.id + '-' + Math.floor(Date.now())" />
            </td>
          </tr>
        </table>

        <b-row ref="row2" id="button_row">
          <b-col md="2" class="mt-2 pt-1 col text-center">
           

            <b-button variant="primary" disabled v-if="is_loader_running">
              <b-spinner small type="grow"></b-spinner>
              Please wait...
            </b-button>

            <b-button id="btn_submit" value="submit" variant="primary" type="submit" v-else>
              {{  $t('Submit')  }}
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BCardText, BFormDatepicker
} from "bootstrap-vue";
import { required } from "@validations";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import apiCall from "@/libs/axios";
//import constants from "@core/utils/constants";
//import download from "downloadjs";

export default {
  name: "StudentMarkUpload",
  components: {
    BCardCode,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BRow,
    BCol,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      moment,
      required,
      classes_id: null,
      month_year: null,
      version_id: null,
      payment_head_id: null,
      wing_id: null,
      student_group_id: null,
      obtained_mark: [],
      academic_year_id: null,
      uploaded_canteen_bills: [],
      is_loader_running: false
    };
  },
  methods: {

    getUploadedBill() {
      apiCall.get('/get/filtered/uploaded/canteen/bills', {
        params: {
          version_id: parseInt(this.version_id),
          payment_head_id: parseInt(this.payment_head_id),
          academic_year_id: parseInt(this.academic_year_id),
          month_year: parseInt(this.month_year)
        }
      })
        .then(response => {

          this.uploaded_canteen_bills = response.data;
        })
    },

    getBillInfo(student_id, fee_type_id) {
      let uploadedBill = this.uploaded_canteen_bills.find(
        (item) =>
          item.student_id === parseInt(student_id) &&
          item.student_fee_type_id === parseInt(fee_type_id)

      );

      if (uploadedBill) {
        return uploadedBill.amount;
      }
      else {
        return 0;
      }
    },

    getBillProperties(){

      let assignedBill = new FormData();
      assignedBill.append('version_id', document.getElementById("version_id").value);
      assignedBill.append('payment_head_id', document.getElementById("payment_head_id").value);
      assignedBill.append('academic_year_id', document.getElementById("academic_year_id").value);
      assignedBill.append('month_year', document.getElementById("month_year").value);
      assignedBill.append('payment_last_date', this.payment_last_date);

      return assignedBill;
    },
    saveChangedCanteenBill($event, student_id, student_fee_type_id) {
      let assignedBillInfo = new FormData();

      let isHostelCharge = (document.getElementById("payment_head_id").value == 1) ? 0: 1;
      assignedBillInfo = this.getBillProperties();
      assignedBillInfo.append('amount', $event);
      assignedBillInfo.append('student_id', student_id);
      assignedBillInfo.append('student_fee_type_id', student_fee_type_id);
      assignedBillInfo.append('is_hostel_charge', isHostelCharge);

      apiCall.post("/saved/changed/student/canteen/bill", assignedBillInfo)
        .then(() => {
          // console.log(response.data.message);
        })
        .catch(() => {
          // console.log(error.response.data.message);
        });
    },
    saveCanteenBills() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$swal({
            title: 'Are you sure?',
            text: "You would like to submit the bill.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, submit it!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            this.is_loader_running = true;

            if (result.value) {

             let bill_info = new FormData();
             bill_info = this.getBillProperties();

             apiCall.post("/submit/student/canteen/bill", bill_info)
                .then((response) => {
                  if (response.data.status === "success") {
                    this.$toaster.success(response.data.message);
                  } else{
                    this.$toaster.error(response.data.message);
                  }
                })
                .catch((error) => {
                  if (error.response.status == 422) {
                    Object.keys(error.response.data.errors).map((field) => {
                      this.$toaster.error(error.response.data.errors[field][0]);
                    });
                  } else this.$toaster.error(error.response.data.message);
                }).finally(()=>{
                  this.is_loader_running = false;
                });

            }
            else{
              this.is_loader_running = false;
            }
          })
        }
      })
    },
  },
  computed: {
    studentList() {
     
      let students = this.$store.getters.students.filter((item) => {
        if (
          item.version_id === parseInt(this.version_id) &&
          item.is_active === 1 &&
          item.academic_year_id === parseInt(this.academic_year_id)
        )
          return item;
      });

      students = students.sort((a, b) => a.nick_name.localeCompare(b.nick_name));
      return students;
    },

    getFeeTypeList() {
    
      const billList = this.student_fee_types.filter((feeHead) => {
        if (feeHead.student_fee_head_id === parseInt(this.payment_head_id))
          return feeHead;
      });
      
      return billList;
    },

    ...mapGetters([
      "students",
      "classes",
      "studentIdByID",
      "studentNameByID",
      "cadetIdByID",
      'wings',
      'student_groups',
      'shifts',
      'versions',
      'academic_years',
      'student_fee_types',
      'student_fee_heads'
    ]),
  },
  created() {
    if (this.students.length < 1) this.$store.dispatch("GET_ALL_STUDENT");
    if (this.classes.length < 1) this.$store.dispatch("GET_ALL_CLASSES");
    if (this.shifts.length < 1) this.$store.dispatch("GET_ALL_SHIFT");
    if (this.versions.length < 1) this.$store.dispatch("GET_ALL_VERSION");
    if (this.wings.length < 1) this.$store.dispatch('GET_ALL_WING');
    if (this.academic_years.length < 1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    if (this.student_fee_types.length < 1) this.$store.dispatch('GET_ALL_STUDENT_FEE_TYPE');
    if (this.student_groups.length < 1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if (this.student_fee_heads.length < 1) this.$store.dispatch('GET_ALL_STUDENT_FEE_HEAD');
  },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
